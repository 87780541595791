.navbar {
  z-index: 20;

  @media (max-width: 1355px) {
    :global(.bp3-button):not(:global(.bp3-intent-primary)) {
      :global(.bp3-icon) {
        margin-right: 0;
      }

      :global(.bp3-icon-chevron-down),
      :global(.bp3-button-text) {
        display: none;
      }
    }
  }

  @media (max-width: 550px) {
    :global(.bp3-navbar-heading),
    :global(.bp3-navbar-divider) {
      display: none;
    }
  }
}

.userMenuItem {
}

.userMenuItem img {
  width: 32px;
  height: 32px;
  border-radius: 16px;
}
