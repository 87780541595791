.printable {
    font-size: 11px;
}

.printable header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    flex-direction: row;
}

:global {
    @media print {
        .content-container {
            flex: unset !important;
            overflow-y: visible !important;
            overflow-x: visible !important;
        }
    }
}

@media print and (color) {
    .printable {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
    }
}


.businessInfo, .printableInfo {
    top: 0px;
}

.businessInfo .businessName, .printableTitle {
    font-weight: bold;
    @apply uppercase text-2xl;
}

.businessInfo p {
    margin-bottom: 0.1em;
}

.businessInfo .businessAddressLine {

}

.businessInfo .businessContactLine {

}


@media not print {
    .printoutPageContent {
        @apply mx-auto p-5 shadow bg-white;
        max-width: 8.25in;
    }

    .printoutPage {
        @apply bg-gray-100 py-5;
    }
}

@media print {
    :global(.bp3-navbar),:global(.bottom-bar) {
        visibility: hidden !important;
        display: none !important;
    }
}

.policyHeader {
    text-transform: uppercase;
    font-weight: bold;
}

.policyPoints {
    list-style-type: circle;
}

.policyPoints li {
    margin-left: 20px;
}

.lineItemTable {
    width: 100%;
    @apply mb-5;
}

.lineItemTable tr:nth-of-type(odd) {
    background: #eee;
}

.lineItemTable th {
    background: #333;
    color: white;
    font-weight: bold;
}

.lineItemTable td, .lineItemTable th {
    padding: 5px;
    border: 1px solid #ccc;
    text-align: left;
}

.lineItemTable tr td p, .lineItemTable tr td {
    color: black;
}

.signature {
    border: 0;
    border-bottom: 1px solid #000;
    font-size: 24px;
    margin-bottom: 5px;
}