.tableHeader {
  border: 1px solid #e3e3e3;
  border-radius: 5px 5px 0 0;
  background-color: rgba(243, 244, 246, 0.5);
  display: flex;
  align-items: center;

  &.collapsed {
    border-radius: 5px;
    border-bottom: 1px solid #e3e3e3;
  }
}

.tableWrapper {
  border: 1px solid #e3e3e3;
  border-radius: 4px;
  background: white;

  &.hasHeader {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top-width: 1px;
  }

  &.hasFooter {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-width: 1px;
  }
}

table.table {
  width: 100%;

  thead {
    background-color: rgba(243, 244, 246, 0.5);
    @apply border-b;
    th {
      @apply p-1 border-r text-left text-xs font-semibold;
    }
  }

  th, tr, td {
    //box-shadow: none !important;
  }

  tr {
    align-items: stretch;
    padding: 0;
    @apply border-b;
    &:nth-child(even) {
      @apply bg-gray-50;
    }
    td {
      @apply p-1 border-r;
      vertical-align: top;
    }
  }

  tr:last-child {
    border-bottom: none;
  }

  td:last-child, th:last-child {
    border-right: none;
  };

  th > div > div {
    margin: -1px;
    padding: 1px 2px;
    border-radius: 2px;
    transition: background-color ease-in-out 150ms;
  }
  
  th:global(.sortable) {
    cursor: pointer;
  }

  th:global(.sortable):hover > div > div {
    @apply bg-gray-200;
  }

  th:global(.sortable):active > div > div {
    @apply bg-gray-300;
  }



  :global(td.with-button) {


    :global(.bp3-popover-wrapper),
    :global(.bp3-popover-target) {
    }
  }
}

.tableFooter {
  border: 1px solid #e3e3e3;
  border-radius: 0 0 5px 5px;
  border-top: none;
  background-color: rgba(243, 244, 246, 0.5);
}

.borderless {
  border: none;
}
