.projectNavItem {
  display: flex;
  flex-direction: row;
  @apply p-2;
  border-left: 1px solid rgb(229, 231, 235);
  border-right: 1px solid rgb(229, 231, 235);
  margin-right: -1px;
  border-top: 0;
  border-bottom: 0;
  align-items: center;
  justify-content: center;

  &:global(.active) {
    box-shadow: inset 0 -3px 0 0 rgb(19, 124, 189);
    margin-bottom: -1px;
    margin-top: -1px;
  }

  p {
    color: rgba(0, 0, 0, 0.7);
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    letter-spacing: 0.1px;
    margin: 0;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;
  }

  &:active {
    background-color: rgb(244, 244, 245);
  }
}
