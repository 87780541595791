.expandableTextArea {
  padding: 4px 10px !important;
  height: 30px !important;
  transition: height 0.1s ease-in-out;
  line-height: 21px !important;
  overflow: hidden;
  resize: none;
}

.expandableTextArea:focus {
  height: 80px !important;
  overflow: auto;
}