.projectSubnavbar {
  @apply pl-2;
  position: sticky;
  top: 35px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #e5e7eb;
  border-top: 1px solid #e5e7eb;
  background-color: rgba(249, 250, 251, 0.3);
  z-index: 10;
  background-color: white;
  min-height: 42px;
}

.projectSubnavbar h3 {
  margin: 0;
  font-weight: 500;
  font-size: 18px;
}

.projectSubnavbar ul {
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.projectSubnavbar ul li a {
  display: flex;
  flex-direction: row;
  height: 40px;
  @apply px-3;
  border-left: 1px solid rgb(229, 231, 235);
  border-right: 1px solid rgb(229, 231, 235);
  margin-right: -1px;
  border-top: 0;
  border-bottom: 0;
  align-items: center;
  justify-content: center;

  &:global(.active) {
    box-shadow: inset 0 -3px 0 0 rgb(19, 124, 189);
    margin-bottom: -1px;
    margin-top: -1px;
  }

  span {
    @apply mr-2;
  }

  &:hover {
    color: rgba(0, 0, 0, 0.7);
    text-decoration: none;
    background-color: #fff;
    cursor: pointer;
  }

  &:active {
    background-color: rgb(244, 244, 245);
  }
}
