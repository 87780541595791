@tailwind base;
@tailwind components;
@tailwind utilities;

body,
#root {
  height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

.filter-menu .bp3-popover-content > .bp3-menu {
  padding: 0 !important;
  overflow: hidden !important;
}

.bp3-toast-container-bottom {
  z-index: 500;
  margin-bottom: 50px;
}

.bp3-drawer.bp3-position-bottom {
  padding-bottom: 52px !important;
}

.content-container {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;

}
